import { Col, Layout, Row, Typography } from "antd";
import MainFooter from "components/Footer";
import MainHeader from "components/Header";
import ContactUsForm from "components/form/ContactUsForm";
import { Component } from "react";

const { Title } = Typography;
const { Content } = Layout;
export default class Contact extends Component {
  render() {
    return (
      <>
        <Layout className="layout-default layout-signin">
          <MainHeader />
          <Content className="signin" style={{ marginBottom: "50px" }}>
            <Row gutter={[24, 0]} justify="center">
              <Col
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                style={{ textAlign: "center" }}
              >
                <Title className="mb-15">Contact Us</Title>
                {/* <Title className="font-regular text-muted" level={5}>
                  Any questions reach us at{" "}
                  <a href="mailto:support@egventures.net">
                    support@egventures.net
                  </a>
                </Title> */}
              </Col>
            </Row>
            <Row gutter={[24, 0]} justify="center">
              <Col
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                style={{ textAlign: "center" }}
              >
                <ContactUsForm />
              </Col>
            </Row>
          </Content>
          <MainFooter />
        </Layout>
      </>
    );
  }
}

import { EllipsisOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
const { Footer } = Layout;
function MainFooter() {
  const StyledLink = styled(RouterLink)`
    color: black;
    text-decoration: none;
    role: menuitem;

    &:hover {
      color: blue;
    }
  `;
  return (
    <Footer>
      <Menu
        mode="horizontal"
        expandIcon={<EllipsisOutlined />}
        style={{ width: "100%", justifyContent: "center" }}
      >
        <Menu.Item>
          <StyledLink to="/">Home</StyledLink>
        </Menu.Item>
        <Menu.Item>
          <StyledLink to="/about">About Us</StyledLink>
        </Menu.Item>
        <Menu.Item>
          <StyledLink to="/contact">Contact Us</StyledLink>
        </Menu.Item>
      </Menu>
      {/* <Menu
        mode="horizontal"
        className="menu-nav-social"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <Menu.Item>
          <StyledLink to="https://www.facebook.com/egventuresholdings?ref=egventuresholdings">
            {<FacebookOutlined />}
          </StyledLink>
        </Menu.Item>
        <Menu.Item>
          <StyledLink to="https://www.instagram.com/eg_ventures?ref=eg_ventures">
            {<InstagramOutlined />}
          </StyledLink>
        </Menu.Item>
        <Menu.Item>
          <StyledLink to="https://www.linkedin.com/company/eg-ventures/?">
            {<LinkedinOutlined />}
          </StyledLink>
        </Menu.Item>
        <Menu.Item>
          <StyledLink to="https://twitter.com/eg_ventures?ref=eg_ventures">
            {<TwitterOutlined />}
          </StyledLink>
        </Menu.Item>
      </Menu> */}
      <p className="copyright">
        {" "}
        Copyright © {new Date().getFullYear()} Shart.app
        {/*by
         <a
          href="https://egventures.net"
          className="font-weight-bold"
          target="_blank"
          rel="noreferrer"
          style={{ color: "black" }}
        >
          EG Ventures
        </a>
        What will you imagine?{" "} */}
      </p>
    </Footer>
  );
}

export default MainFooter;

import "assets/styles/antd.css";
import "assets/styles/main.css";
import "assets/styles/responsive.css";
import { Route, Switch } from "react-router-dom";
import About from "views/About";
import Contact from "views/Contact";
import Home from "views/Home";
function App() {
  return (
    <>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
      </Switch>
    </>
  );
}

export default App;

import { Button } from "antd";

function SoundButton({ soundSrc, label }) {
  const playSound = () => {
    new Audio(soundSrc).play();
  };

  return <Button onClick={playSound}>{label}</Button>;
}

export default SoundButton;

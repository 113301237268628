import { Layout, Space } from "antd";
import shartSound from "assets/sounds/shart.mp3";
import shartSound1 from "assets/sounds/shart1.mp3";
import shartSound2 from "assets/sounds/shart2.mp3";
import shartSound3 from "assets/sounds/shart3.mp3";
import MainFooter from "components/Footer";
import MainHeader from "components/Header";
import HeroBanner from "components/HeroBanner";
import SoundButton from "components/SoundButton";
import { Component } from "react";
const { Content } = Layout;

export default class Home extends Component {
  render() {
    return (
      <Layout className="layout-default layout-signin">
        <MainHeader />
        <Content className="signin" style={{ padding: "0 50px 50px" }}>
          <HeroBanner />
          <Space
            direction="vertical"
            size="large"
            align="center"
            style={{ width: "100%", gap: "50px" }}
          >
            <SoundButton soundSrc={shartSound} label="Wet Shart" />
            <SoundButton soundSrc={shartSound1} label="Bubbly Shart" />
            <SoundButton soundSrc={shartSound2} label="Huge Wet Shart" />
            <SoundButton soundSrc={shartSound3} label="Long Windy Shart" />
          </Space>
        </Content>
        <MainFooter />
      </Layout>
    );
  }
}

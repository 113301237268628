import { Card, Col, Collapse, Divider, Layout, Row, Typography } from "antd";
import MainFooter from "components/Footer";
import MainHeader from "components/Header";
import { Component } from "react";

const { Content } = Layout;
const { Title, Text, Link } = Typography;
const { Panel } = Collapse;

const faqData = [
  [
    "What is the product?",
    "Shart.app is a for fun ad filled web application for users to try different shart sounds.",
  ],
  ["What are the brand's values?", "We want to keep users entertained."],
  [
    "What does the brand want the customer to know?",
    "We want the customer to know that we develop all sorts of apps.",
  ],
  [
    "Who is your customer avatar?",
    "Our customer avatar is a person who wants to quickly entertain others.",
  ],
  [
    "What problem do they have?",
    "They have the problem of not being able to quickly have a shart button.",
  ],
  [
    "What do most of these customers say about this problem?",
    "They say that they want to stay entertained.",
  ],
  [
    "What do most of these customers say about this product?",
    "This product helps keep them entertained.",
  ],
  [
    "How are these customers getting this message?",
    "We have a website with ad revenue and a social media campaign.",
  ],
  ["What actions do you want them to take?", "We want them to have fun!."],
  [
    "How do they like to be spoken to?",
    "They like to be spoken to in a friendly and professional manner.",
  ],
  ["What is the name of business?", "Shart.app"],
  [
    "What is your business in one sentence?",
    "Shart.app is a for fun entertainment app with ads.",
  ],
  [
    "What problem is your business solving?",
    "We want to keep users entertained while collecting ad revenue.",
  ],
  [
    "What is your specific solution to the problem?",
    "An app that quickly interfaces with mobile and desktop.",
  ],
  ["How do you make money?", "We make money by ad revenue."],
  ["What will revenue be this year?", "We expect to make $100,000 this year."],
  [
    "What is the big vision?",
    "We want to be the go-to place for shart entertainment.",
  ],
  [
    "Why are you the right person to solve this problem?",
    "I am the right person to solve this problem because I can quickly spin up entertainment websites with ad revenue.",
  ],
  [
    "How did you come up with this idea?",
    "I came up with this idea because I need money.",
  ],
  [
    "What's your scrapiest story?",
    "My scrapiest story is that shart.com was bought but never used!.",
  ],
];

export default class About extends Component {
  render() {
    return (
      <>
        <Layout className="layout-default layout-signin">
          <MainHeader />
          <Content
            className="signin"
            style={{ marginTop: "20px", marginBottom: "50px" }}
          >
            <Row gutter={[24, 0]} justify="center">
              <Col
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                style={{ textAlign: "center" }}
              >
                <Title className="mb-15">About Us</Title>
                <Divider style={{ opacity: "0" }} />
                {/* <Text style={{ fontSize: "20px" }}>Shart.app is an </Text>
                <Link
                  style={{ fontSize: "20px" }}
                  href="https://egventures.net"
                  target="_blank"
                >
                  EG Ventures
                </Link>
                <Text style={{ fontSize: "20px" }}> company!</Text> */}
              </Col>
            </Row>
            <Divider style={{ opacity: "0" }} />
            <Row gutter={[24, 0]} justify="center">
              <Col
                xs={{ span: 24 }}
                lg={{ span: 24 }}
                md={{ span: 24 }}
                style={{ textAlign: "center" }}
              >
                <Title level={2}>FAQ</Title>
                <Card style={{ margin: "0 auto", maxWidth: "800px" }}>
                  <Collapse accordion>
                    {faqData.map((item, index) => (
                      <Panel header={item[0]} key={index}>
                        <p style={{ whiteSpace: "normal" }}>{item[1]}</p>
                      </Panel>
                    ))}
                  </Collapse>
                </Card>
              </Col>
            </Row>
          </Content>
          <MainFooter />
        </Layout>
      </>
    );
  }
}

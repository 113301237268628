import { Typography } from "antd";
const { Title, Text } = Typography;

function HeroBanner() {
  return (
    <div style={{ textAlign: "center", padding: "50px 0" }}>
      <Title level={1}>Shart.app</Title>
      <Text
        className="font-regular text-muted ant-typography-h3"
        style={{
          color: "black",
          marginBottom: "30px",
          whiteSpace: "pre-line",
          fontSize: "20px",
        }}
      >
        "Here I sit broken hearted, came to fart but really sharted. -
        Anonymous"
      </Text>
    </div>
  );
}

export default HeroBanner;
